@charset "utf-8";
h1, h2, h3, h4, h5, h6 {margin:0}
/* 버튼 */
.text_center {text-align:center !important}
.g_text_a {font-weight:600}

/* 버튼 */



.g_btn_wrap {text-align:center; margin-top:15px}
.g_btn_wrap.w04 {margin-top:30px}

/* 서브 상단 */
.g_sub_top {width:100%; min-width:980px;  height:130px; padding:60px 0; text-align:left; background:url('/assets/sub/sub_top_img.jpg') center no-repeat; background-size:cover;}
.g_sub_top .g_sub_top_text {max-width:1280px; min-width:900px; margin:0 auto}
.g_sub_top h3 {font-size:60px; margin-bottom:15px}
.g_sub_top .site_step {display:flex; justify-content:left; font-family:'Noto Sans CJK KR', Arial, sans-serif}
.g_sub_top .site_step div {padding:0 10px}
.g_sub_top .site_step div a {color:#333}
.g_sub_top .site_step svg {width:17px; margin-right: 10px;}
.g_sub_top .site_step img {margin-right: 5px;}

/* title */
.g_title_b:after {content:''; clear:both; display:block}
.g_title_b {margin-top:50px}
.g_title_b h5 {float:left; font-size:25px; line-height:25px; width:18%}
.g_title_b .line_bar {float:right; height:3px; background:#333; width:82%}
.g_title_b.w02 {margin-top:0}
.g_title_c {font-size:17px; color:var(--GridaBlue); margin:25px 0 10px 0}

/* input */
.g_input_a .input_wrap:after {content:''; clear:both; display:block}
.g_input_a .input_wrap {margin-top:25px}
.g_input_a .input_wrap label {width:18%; float:left; line-height:50px;}
.g_input_a .input_wrap label img {vertical-align:text-top; padding-left:5px}
.g_input_a .input_wrap input {width:82%; float:left; box-sizing:border-box; border:1px solid #ccc;height:50px; padding:0 2%}
.g_input_a .input_wrap input::placeholder {color:#bbbbbb}
.g_input_a .input_wrap.w02 input {width:69%}
.g_input_a .input_wrap.w02 button {width:12%; float:right; box-sizing:border-box; line-height:48px; border:1px solid var(--GridaBlue); color:var(--GridaBlue); background:#fff}
.g_input_a .input_wrap.w03 button {padding:0 70px; float:left; box-sizing:border-box; line-height:48px; border:1px solid var(--GridaBlue); color:var(--GridaBlue); background:#fff}

input[type="radio"]:checked + label::before {background:var(--GridaBlue); border-color:var(--GridaBlue)}
input[type="radio"]:checked + label::after {opacity:1}
input[type="radio"] + label {position:relative; padding-left:35px}
input[type="radio"] {display:none;}
input[type="radio"] + label::before{content:""; position:absolute; top:0px; left:2px; width:20px; height:20px; border-radius:25px; background-color:#fff; border:1px solid #ccc;}
input[type="radio"] + label::after{content:""; position:absolute; top:6px; left:7px; width:12px; height:6px; background-color: transparent; border-bottom:2px solid #fff; border-left:2px solid #fff; border-radius:2px; transform:rotate(-45deg); opacity:0; transition:all .3s;}

.radio_middle input[type="radio"] + label {line-height:22px}



/* table */
.g_table_a {margin-bottom:5px}
.g_table_a th {font-size:17px; font-weight:600; text-align:center; background:#f1f1f1; border:1px solid #ccc}
.g_table_a th, .g_table_a td {border-left:1px solid #ccc; line-height:17px; height:45px; padding:0 15px}
.g_table_a td {border:solid #ccc; border-width:1px; font-size:14px; color:#5e7290}
.g_table_a .td_title td {font-weight:500; color:#333}

/* 포인트컬러 */
.g_point_a {color:var(--GridaBlue) !important;}
.g_point_c {color:#5e7290 !important; font-weight:400 !important;}


/* 리스트 스타일*/
.g_list_a {color:#5e7290; margin-top:20px}
