@charset "utf-8";

@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* reset */
html,body{margin:0px;padding:0px;width:100%; font-size:15px;color:#333; font-family:'roboto', 'Noto Sans CJK KR', Arial, sans-serif;-webkit-text-size-adjust:none;-webkit-font-smoothing:antialiased;/*letter-spacing:-1px*/}
ul,ol,li,dl,dd,dt,input,p,input,button,textarea,fieldset,legend,td,th{margin:0px;padding:0px;word-break:keep-all}
h1,h2,h3,h4,h5,h6,b {font-weight:bold; padding:0; margin:0}
li{list-style:none}
fieldset,img,a{border:0px}
a{text-decoration:none; outline: none;}
a:active, a:focus {color:unset}    
legend,.blind{position:absolute;top:0;left:-10000px;width:0;height:0;overflow:hidden;font-size:0;line-height:0}
caption{display:none}
button{cursor:pointer}
button{outline:none; overflow:visible\9}	/* button fix for ie8 */
input,textarea,button,select,option{font-family: NotoSans, Arial, sans-serif;}
table{width:100%;border-collapse:collapse;border-spacing:0;text-align:left; table-layout:fixed}
table th, table td{text-align:left; word-break:break-word;word-wrap:break-word;}
input::-webkit-input-placeholder{color:#929292}
input::-moz-placeholder{color:#929292}
input:-moz-placeholder{color:#929292}
input:-ms-input-placeholder{color:#929292}
textarea::-webkit-input-placeholder{color:#929292}
textarea::-moz-placeholder{color:#929292}
textarea:-moz-placeholder{color:#929292}
textarea:-ms-input-placeholder{color:#929292}
input:focus {border:0;outline:none;}
input:focus::-webkit-input-placeholder{color:transparent!important}
input:focus::-moz-placeholder{color:transparent!important}
input:focus:-moz-placeholder{color:transparent!important}
input:focus:-ms-input-placeholder{color:transparent!important}
textarea:focus::-webkit-input-placeholder{color:transparent!important}
textarea:focus::-moz-placeholder{color:transparent!important}
textarea:focus:-moz-placeholder{color:transparent!important}
textarea:focus:-ms-input-placeholder{color:transparent!important}
header, footer, section, article, aside, nav, address { display: block; margin:0; } /* ie8 */
/* button:hover {box-shadow:1px 1px 4px #333; transition: .3s;} */
#small_gray {
  font-size: 12px;
  color: #a2a2a2;
  padding-left: 5px;
}
.user_wrap{
  width: 100%; padding-left:245px; background:#f8f9ff; height: 100%;
}
@media screen and (max-width: 1024px) {
  .user_wrap{
    padding-left: 20px;
  }
}
