@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@charset "utf-8";

/* reset */
html,body{margin:0px;padding:0px;width:100%; font-size:15px;color:#333; font-family:'roboto', 'Noto Sans CJK KR', Arial, sans-serif;-webkit-text-size-adjust:none;-webkit-font-smoothing:antialiased;/*letter-spacing:-1px*/}
ul,ol,li,dl,dd,dt,input,p,input,button,textarea,fieldset,legend,td,th{margin:0px;padding:0px;word-break:keep-all}
h1,h2,h3,h4,h5,h6,b {font-weight:bold; padding:0; margin:0}
li{list-style:none}
fieldset,img,a{border:0px}
a{text-decoration:none; outline: none;}
a:active, a:focus {color:unset}    
legend,.blind{position:absolute;top:0;left:-10000px;width:0;height:0;overflow:hidden;font-size:0;line-height:0}
caption{display:none}
button{cursor:pointer}
button{outline:none; overflow:visible\9}	/* button fix for ie8 */
input,textarea,button,select,option{font-family: NotoSans, Arial, sans-serif;}
table{width:100%;border-collapse:collapse;border-spacing:0;text-align:left; table-layout:fixed}
table th, table td{text-align:left; word-break:break-word;word-wrap:break-word;}
input::-webkit-input-placeholder{color:#929292}
input::-moz-placeholder{color:#929292}
input:-moz-placeholder{color:#929292}
input:-ms-input-placeholder{color:#929292}
textarea::-webkit-input-placeholder{color:#929292}
textarea::-moz-placeholder{color:#929292}
textarea:-moz-placeholder{color:#929292}
textarea:-ms-input-placeholder{color:#929292}
input:focus {border:0;outline:none;}
input:focus::-webkit-input-placeholder{color:transparent!important}
input:focus::-moz-placeholder{color:transparent!important}
input:focus:-moz-placeholder{color:transparent!important}
input:focus:-ms-input-placeholder{color:transparent!important}
textarea:focus::-webkit-input-placeholder{color:transparent!important}
textarea:focus::-moz-placeholder{color:transparent!important}
textarea:focus:-moz-placeholder{color:transparent!important}
textarea:focus:-ms-input-placeholder{color:transparent!important}
header, footer, section, article, aside, nav, address { display: block; margin:0; } /* ie8 */
/* button:hover {box-shadow:1px 1px 4px #333; transition: .3s;} */
#small_gray {
  font-size: 12px;
  color: #a2a2a2;
  padding-left: 5px;
}
.user_wrap{
  width: 100%; padding-left:245px; background:#f8f9ff; height: 100%;
}
@media screen and (max-width: 1024px) {
  .user_wrap{
    padding-left: 20px;
  }
}

@charset "utf-8";
h1, h2, h3, h4, h5, h6 {margin:0}
/* 버튼 */
.text_center {text-align:center !important}
.g_text_a {font-weight:600}

/* 버튼 */



.g_btn_wrap {text-align:center; margin-top:15px}
.g_btn_wrap.w04 {margin-top:30px}

/* 서브 상단 */
.g_sub_top {width:100%; min-width:980px;  height:130px; padding:60px 0; text-align:left; background:url('/assets/sub/sub_top_img.jpg') center no-repeat; background-size:cover;}
.g_sub_top .g_sub_top_text {max-width:1280px; min-width:900px; margin:0 auto}
.g_sub_top h3 {font-size:60px; margin-bottom:15px}
.g_sub_top .site_step {display:flex; justify-content:left; font-family:'Noto Sans CJK KR', Arial, sans-serif}
.g_sub_top .site_step div {padding:0 10px}
.g_sub_top .site_step div a {color:#333}
.g_sub_top .site_step svg {width:17px; margin-right: 10px;}
.g_sub_top .site_step img {margin-right: 5px;}

/* title */
.g_title_b:after {content:''; clear:both; display:block}
.g_title_b {margin-top:50px}
.g_title_b h5 {float:left; font-size:25px; line-height:25px; width:18%}
.g_title_b .line_bar {float:right; height:3px; background:#333; width:82%}
.g_title_b.w02 {margin-top:0}
.g_title_c {font-size:17px; color:var(--GridaBlue); margin:25px 0 10px 0}

/* input */
.g_input_a .input_wrap:after {content:''; clear:both; display:block}
.g_input_a .input_wrap {margin-top:25px}
.g_input_a .input_wrap label {width:18%; float:left; line-height:50px;}
.g_input_a .input_wrap label img {vertical-align:text-top; padding-left:5px}
.g_input_a .input_wrap input {width:82%; float:left; box-sizing:border-box; border:1px solid #ccc;height:50px; padding:0 2%}
.g_input_a .input_wrap input::-webkit-input-placeholder {color:#bbbbbb}
.g_input_a .input_wrap input::placeholder {color:#bbbbbb}
.g_input_a .input_wrap.w02 input {width:69%}
.g_input_a .input_wrap.w02 button {width:12%; float:right; box-sizing:border-box; line-height:48px; border:1px solid var(--GridaBlue); color:var(--GridaBlue); background:#fff}
.g_input_a .input_wrap.w03 button {padding:0 70px; float:left; box-sizing:border-box; line-height:48px; border:1px solid var(--GridaBlue); color:var(--GridaBlue); background:#fff}

input[type="radio"]:checked + label::before {background:var(--GridaBlue); border-color:var(--GridaBlue)}
input[type="radio"]:checked + label::after {opacity:1}
input[type="radio"] + label {position:relative; padding-left:35px}
input[type="radio"] {display:none;}
input[type="radio"] + label::before{content:""; position:absolute; top:0px; left:2px; width:20px; height:20px; border-radius:25px; background-color:#fff; border:1px solid #ccc;}
input[type="radio"] + label::after{content:""; position:absolute; top:6px; left:7px; width:12px; height:6px; background-color: transparent; border-bottom:2px solid #fff; border-left:2px solid #fff; border-radius:2px; -webkit-transform:rotate(-45deg); transform:rotate(-45deg); opacity:0; transition:all .3s;}

.radio_middle input[type="radio"] + label {line-height:22px}



/* table */
.g_table_a {margin-bottom:5px}
.g_table_a th {font-size:17px; font-weight:600; text-align:center; background:#f1f1f1; border:1px solid #ccc}
.g_table_a th, .g_table_a td {border-left:1px solid #ccc; line-height:17px; height:45px; padding:0 15px}
.g_table_a td {border:solid #ccc; border-width:1px; font-size:14px; color:#5e7290}
.g_table_a .td_title td {font-weight:500; color:#333}

/* 포인트컬러 */
.g_point_a {color:var(--GridaBlue) !important;}
.g_point_c {color:#5e7290 !important; font-weight:400 !important;}


/* 리스트 스타일*/
.g_list_a {color:#5e7290; margin-top:20px}

@charset "utf-8";

/* 컨텐츠 */
.contains{margin:0 auto; min-width:1280px}
.content{position:relative; }
.content.gray {background:#f7f7f7}
p { letter-spacing:-0.5px;}
.color_gold {color:var(--GridaBlue) !important}
svg {vertical-align:text-bottom;}
/* 프로그래스바 */

/* 헤더 */
#fixed_bar{z-index: 1;}
#fixed_bar2{margin-top: 80px;z-index: 1;}

/* 메인 */
.main_top_wrap .top_content:after, .workstation_info:after, .price_guide_info:after {content:''; display:block; clear:both}
.main_top_wrap {background:url('/assets/main/top_back.jpg') center no-repeat; height:855px}
.main_top_wrap .top_content {max-width:1280px; min-width:900px; margin:0 auto; padding-top:240px; position:relative}
.main_top_wrap .top_content .main_top_left h4 {font-size:70px}
.main_top_wrap .top_content .main_top_left .title_two {font-size:50px; color:#333; font-weight:600; margin-bottom:40px; line-height:25px}
.main_top_wrap .top_content .main_top_left p {font-size:20px; color:#5e7290}

.main_top_btn {margin-top:120px; cursor: pointer; position:relative; padding:17px 35px; width:215px; border-radius:5px; font-size:20px; background:var(--GridaBlue); color:#fff; display:inline-block}
.main_top_btn:hover {background:#006de2;}
.main_top_btn:focus {color:#fff}
.main_top_btn svg {position:absolute; right:35px}
 
.content_wrap {max-width:1280px; min-width:900px; padding:100px 0; margin:0 auto}
.g_title {text-align:center; margin-bottom:50px}
.g_title strong {color:var(--GridaBlue); font-size:15px; font-weight:900}
.g_title h3 {font-size:45px; }
.g_title p {font-size:17px}

/*워크스테이션*/


/*요금안내*/

/* 팝업 */ 


.popup_wrap.wlogin {width:400px; padding:50px }
.popup_wrap .pop_con_wrap {padding:30px}
.popup_wrap .input_wrap input {width:100%;  margin-bottom:10px; box-sizing:border-box; border:1px solid #ccc;height:50px; padding:0 2%}
.popup_wrap .input_wrap {position:relative}
.popup_wrap .login_fail {font-size:13px; color:#ff2c2c; position:absolute; bottom:-25px; left:0}
.popup_wrap .logo_img {text-align:center; margin:10px 0 35px 0;}
.popup_wrap .logo_img img{height: 100px;}
.popup_wrap .logo_img p {font-size:17px; margin-top:15px}

/* 모달창 */

.pop_top_img {text-align:center; border-radius:5px 5px 0 0; padding:29px 0; height:74px; background:url('/assets/sub/pop_top_img.png') center no-repeat}
.pop_top_img h3 {font-size:40px}
.pop_top_img.w02 h3 {line-height:103px}
.popup_wrap .g_input_a .input_wrap label {width:25%}
.popup_wrap .g_input_a .input_wrap input {width:75%}
.popup_wrap .g_input_a .input_wrap {margin-top:0}
.popup_wrap .g_input_a textarea {width:75%; box-sizing:border-box; border:1px solid #ccc; padding:2%}
.popup_wrap .g_input_a {border-bottom:1px solid #333;padding-bottom:25px}


.popup_wrap .calculation_wrap .pop_calc_text {float:right; text-align:right; font-size:17px; line-height:35px}
.popup_wrap .calculation_wrap .pop_calc_text.w02 {line-height:18px}
.popup_wrap .calculation_wrap .pop_calc_text .pop_sub_text {font-size:15px}
.calculation_wrap .g_input_a .input_wrap label {line-height:40px; color:#5e7290}
.calculation_wrap  .g_input_a .input_wrap input {width:20%; height:40px}
.calculation_wrap  .g_input_a .input_left_text {line-height:40px; margin-left:15px; color:#5e7290}
.calculation_wrap .g_input_a .input_wrap {margin-top:25px}
.calculation_wrap .g_title_b:first-child {margin-top:0}
.calculation_wrap .g_title_b .line_bar {width:55%}
.calculation_wrap .g_title_b h5 {width:45%}
.popup_wrap .calculation_wrap .input_wrap input {margin-bottom:0; text-align:right}
.popup_wrap .calculation_wrap:first-child .g_input_a {border-bottom:0; padding-bottom:50px}


/* 푸터 */
footer {border-top:1px solid #dddddd}
footer .content_wrap {padding:25px 0; font-size:14px}

/* 회원가입_약관 */
.sign_info_two {margin-top:100px; padding-bottom:50px; border-bottom:1px solid #333}

#valid input{
  background-color: rgb(232, 240, 254);
}
/* 1280이하 미디어쿼리 */
@media (max-width: 1280px) {
    .g_sub_top .g_sub_top_text {padding:0 30px; }
    .content_wrap, .main_top_wrap .top_content, footer .content_wrap {padding-left:30px; padding-right:30px}
  }
/* 컨텐츠 */
.contains{margin:0 auto; min-width:1280px}
.content{position:relative}
.content.gray {background:#f7f7f7}
p { letter-spacing:-0.5px;}
.color_gold {color:var(--GridaBlue) !important}
svg {vertical-align:text-bottom;}

.g_sub_top{margin-top: 80px;}
/* 레프트메뉴 */



/* 컨텐츠 */


.no_data{text-align: center;}
.user_right_con { padding:0 35px; overflow-y: auto; }
.user_right_con .user_title {margin-bottom:40px; line-height:35px; font-size:35px; font-weight:600}
.user_right_con .user_title_with_btn { display: flex; justify-content: space-between;}
.user_right_con .user_title_with_btn button{height:40px; padding:0 20px 0 50px; color:var(--GridaBlue); border: 2px solid var(--GridaBlue); font-weight:600;  border-radius:5px; 
  background:url('/assets/user/download_icon.png')left 20px center no-repeat; background-color:#fff; background-size: 20px; -webkit-filter: brightness(100%); filter: brightness(100%);}
.user_right_con .user_combo_wrap {display:flex; justify-content:space-between; padding-bottom:20px; margin-bottom:55px; border-bottom:1px solid #ddd}





.combo_right .user_search_wrap, .combo_right .dropdown {float:left}
.combo_right .user_search_wrap {width:200px; padding-left:5px; border-bottom:1px solid #aaaaaa; height:40px; box-sizing:border-box; position:relative}
.combo_right .user_search_wrap input {border:0; line-height:40px; background-color:transparent;}
.combo_right .user_search_wrap button {border:0; background:transparent; position:absolute; top:9px; right:5px}
.combo_right .dropdown dd, .dropdown dt, .dropdown ul {margin:0px; padding:0px}
.combo_right .dropdown dd {position:relative}
.dropdown a, .dropdown a:visited { color:#666; text-decoration:none; outline:none; font-size:13px}

.dropdown dt {margin:0 0 0 30px}
.dropdown dt a {height:40px; border-radius:5px; box-sizing:border-box; line-height:30px; background:#fff url('/assets/user/dropdown.png') no-repeat scroll right 10px center; display:block; padding-right:20px; padding-left:10px; border:1px solid #ddd; font-size:13px; width:130px}
.dropdown dt a span {cursor: default; display:block; padding:5px}
.dropdown dd ul {background:#fff; border:1px solid #ddd; border-radius:5px; display:none; left:-12px; padding:0px; overflow:hidden; position:absolute; top:5px; width:auto; min-width:170px; list-style:none; z-index:10;}
.dropdown span.value {display:none}
.dropdown dd ul li a {padding:10px; display:block}
.dropdown dd ul li a:hover {color:#fff; background-color:var(--GridaBlue)}


.user_box_wrap .user_box {display:flex; justify-content:flex-start; flex-wrap:wrap}
.user_box_wrap .user_box li {border:1px solid #ddd; margin:22px; margin-bottom:25px; box-sizing:border-box; background:#f1f1f1; width:20%; max-width: 360px; min-width:300px; border-radius:5px}

.user_box_wrap .user_box li.active {border:1px solid #e2e2e2; background:#fff}
.user_box_wrap .user_box li.active .active_title {color:var(--GridaBlue); font-weight:600}
.user_box_wrap .user_box li.active .user_box_con .user_computer.w02 dl dd .state {background:var(--GridaBlue); color:#fff; font-weight:600; border-color:var(--GridaBlue)}


.user_box_wrap .user_box li .user_box_con dt {width:115px; flex:none}
.user_box_wrap .user_box li .user_box_con dt img {display:inline-block; width:21px; margin-right:3px; vertical-align:middle}
.user_box_wrap .user_box li .user_box_con dd, .user_box_wrap .user_box li .user_box_con dd {color:#5e7290}
.user_box_wrap .user_box li .user_box_con .user_computer {border-bottom:1px solid #ddd; padding-bottom:7px; margin-bottom:12px}
.user_box_wrap .user_box li .user_box_con .user_computer.w02 dl {font-size:13px; line-height:24px;}
.user_box_wrap .user_box li .user_box_con .user_computer.w02 dl dd .state {letter-spacing:0; border:1px solid #5e7290; text-align:center; border-radius:20px; line-height:23px; color:#5e7290; width:90px; height:21px}
.user_box_wrap .user_box li .user_box_con p {font-size:12px; color:#666; line-height:18px; }


.user_box_wrap .user_box li .user_box_con.storage_wrap .user_computer {padding-bottom:20px; margin-bottom:20px}
.user_box_wrap .user_box li .user_box_con.storage_wrap {background:#fff; padding:15px}
.user_box_wrap .user_box li .user_box_con .user_computer.storage_con {text-align:center;}
.user_box_wrap .user_box li .user_box_con .storage_con p.sto_fir {font-size:15px; color:#333}
.user_computer.storage_con h4 {font-size:40px; color:#333; font-weight:500; line-height:80px}

.user_computer dl dt span{font-weight: 600;}
/* 마이페이지 */
.mypage_wrap .mypagt_tab:after, .my_info_wrap:after, .my_credit_wrap:after {content:''; clear:both; display:block}



.my_credit_wrap h5 {line-height:20px; margin-bottom:15px; font-size:20px; font-weight:500; color:#5e7290}
.my_credit_wrap strong {color:var(--GridaBlue)}
.my_box_left, .my_box_right {border:1px solid #ddd; min-height:180px; padding:20px 0; background:#fff; width:50%; text-align:center; box-sizing:border-box}
.my_box_left {border-right:1px dashed #ddd}
.my_box_right {border-left:0}
.my_box_right strong {font-size:20px}
.my_box_right p {font-size:12px}

.my_credit_wrap .my_box_btn button {width:150px; margin-top:15px; color:#fff; background:var(--GridaBlue); line-height:40px; border:0; border-radius:5px}
.my_credit_wrap .my_box_btn button:hover {background:#006fe6;}

.history_table table.history_th_wrap {border:1px solid #ddd; border-radius:5px 5px 0 0; background:#fff}
.user_right_con.my_history_wrap .user_combo_wrap {margin-top:30px; margin-bottom:0; border-bottom:0}
.user_right_con.my_history_wrap button.storage_btn {line-height:40px; padding:0 20px; color:#fff; font-weight:600; border:0; border-radius:5px; background-color:var(--GridaBlue)}
.user_right_con.my_history_wrap button.storage_btn:hover {background-color:#006fe6}
.user_right_con.my_history_wrap .history_data {width:150px; height:35px; margin-top:3px; border:1px solid #ddd}
.history_table th, .history_table td {padding:0 10px; height:50px; position:relative}
.history_table .hitory_td_wrap {overflow:hidden; overflow-y:overlay; max-height:450px}
.history_table .hitory_td_wrap tr {border-bottom:1px solid #ddd}
.history_table .hitory_td_wrap tr:hover {background:#f1f1f1}
.history_table .hitory_td_wrap {margin-top:10px}

.invoice {text-decoration:underline; color:var(--GridaBlue); background:none; border:0}

.invoice svg {width:17px; vertical-align:middle; margin-right:5px}

/* 팝업 */

.user_popup_wrap {background:#fff; position:relative; min-height: 250px;}
.user_popup_wrap.h05 {height: 435px;}
.user_popup_wrap.w02 {width:auto} 
.user_popup_wrap.w03 {width:450px; background:var(--GridaBlue); padding:20px}
.user_popup_wrap.w03 .user_pop_content {background:#fff; padding:10px}
.user_popup_wrap.w04 {width:610px}
.user_popup_wrap.w05 {width:1100px}
.pop_inner_con {background:#fff}
.user_pop_table table {margin-top:20px}
.user_pop_table table th, .user_pop_table table td {height:50px; padding:0 10px}
.user_pop_table table thead tr {border-top:1px solid #333; border-bottom:1px solid #333}
.user_pop_table table tbody tr {border-bottom:1px solid #ddd} 
.user_pop_table table tbody tr:last-child {border-bottom:0} 
.user_total {border:solid #333; border-width:1px 0; padding:15px 10px; text-align:right; font-size:17px}
.user_total strong {color:var(--GridaBlue); font-size:25px}
.pop_history_text {line-height:25px}
.pop_history_text p span {color:var(--GridaBlue)}
.pop_text_wrap_w {margin-top:20px; display:flex; justify-content:space-between}
.user_pop_btn_wrap button{border:0; background:var(--GridaBlue); color:#fff; text-align:center; width:150px; height:40px; border-radius:5px}

.work_stop_strong {display:block; margin-bottom:10px}
.pop_logout_title {font-size:19px; margin-bottom:30px;text-align: center;margin-top: 20px;}
.pop_logout_text {min-height: 100px; text-align: center; display: flex;flex-direction: column; justify-content: center;}
.pop_logout_text strong {color:var(--GridaBlue)}
.trashcan{-webkit-filter: brightness(0);filter: brightness(0); -webkit-filter: invert(1); filter: invert(1);}
.user_popup_wrap .pop_pass_text {text-align:center; margin-top:15px}

.user_pop_content .pop_alarm_p:first-child strong {color:var(--GridaBlue)} 
.user_pop_content .pop_alarm_p {margin-bottom:10px}
.pop_check_wrap label::before, .pop_check_wrap label::after {display:none}
.user_pop_content input {width:60px; line-height:25px; border:1px solid #aaa; margin-right:5px}
.pop_check_wrap {padding-top:10px; margin-top:20px; border-top:1px solid #ddd}
.pop_check_wrap dl {display:flex; justify-content:space-between; border-bottom:1px dashed #ddd; padding-bottom:10px; margin-bottom:10px}
.pop_check_wrap dl:last-child {border-bottom:1px solid #ddd}
.pop_check_wrap dl dt svg {width:20px; vertical-align:top; margin-right:5px; stroke-width:1;}

.user_popup_wrap .work_eidt {display:flex; justify-content:space-around}
.user_popup_wrap .work_eidt label {width:14%; line-height:46px}
.user_popup_wrap .work_eidt input {width:63%; height:40px; padding:0 15px; margin-bottom:7px; border:1px solid #aaa; border-radius:5px}
.user_popup_wrap .work_eidt + p {text-align:center}
.user_computer.h02{height: 80px;}
.pop_inner_con:after {content:''; clear:both; display:block}
.invoice_dl {width:50%; margin-bottom:3px; display:block; float:left; border:1px solid #ddd; box-sizing:border-box}
.invoice_dl:nth-child(2n) {border-left:0}
.invoice_dl dt {background:#f1f1f1; text-align:left; padding:1px 10px; font-size:12px; font-weight:600}
.invoice_dl dt span {font-size:10px; font-weight:400}
.invoice_dl dd {font-weight:600; min-height:23px; line-height:23px; font-size:13px; text-align:center; padding:2px 10px}
.invoice_dl dd.text_line {text-decoration:line-through;}
.pop_inner_con .invo_dl_ban {width:50%}
.invo_table .invoice_dl {width:100%}
.invo_table td .invoice_dl {border-left:1px solid #ddd}
.invo_table td:nth-child(2n) .invoice_dl {border-left:0}
.invo_table td {vertical-align:top}
.invo_title {background:#86a3c3; text-align:center; color:#fff; font-weight:500; padding:4px 0; font-size:14px}
.invo_table .invo_left_td dt {background:#a9c2d8}
.invo_table .invo_left_td dd {background:#f8ffff}
.invo_title.w02 {background:#eb9b46}
.invo_table .invo_right_td dt {background:#f0ca99}
.invo_table .invo_right_td dd {background:#fff8ed}
.invo_total_table {border:1px solid #ddd}
.invo_total_table th, .invo_total_table td {padding:7px 10px; text-align:right; font-size:13px}
.invo_total_table th {border-right:1px solid #ddd; font-weight:500; color:#666; background:#eee}
.invo_total_table .invo_total_tr th, .invo_total_table .invo_total_tr td {padding:5px 10px}
.invo_total_table .invo_total_tr th {text-align:left; }
.invo_total_table .invo_total_tr td {background:#eee; font-weight:600}
.invo_total_table .invo_total_tr th span {font-size:10px}
.invo_total_table tr {border-bottom:1px solid #ddd}
.invoice_btn {text-align:right; margin-top:10px}
.invoice_btn button {background:#fff; color:var(--GridaBlue); font-weight:600; border:0; border-radius:5px; width:100px; line-height:30px}
.invoice_btn button:last-child {margin-left:7px; background:#f1f1f1}
.user_popup_wrap.w03 h3 {margin-bottom:7px}

.produce_spec_wrap {margin-top:30px; padding:10px 20px; background:#f1f1f1; border-radius:5px}
.produce_spec {display:flex; justify-content:space-between}
.produce_spec > li {width:50%; margin:10px 0}
.produce_spec > li:nth-child(2n) {padding-left:3%; width:43%}
.produce_spec strong {height:25px; display:block; color:#333}
.produce_spec ul li {font-size:13px; color:#5e7290; line-height:18px}
.user_popup_wrap.w04 .pop_logout_title {border-bottom:1px solid #ddd; padding-bottom:20px; margin-bottom:0}

.down_loading_bar_w {margin-top:20px}
.loading_bar {position:relative; background:#f1f1f1; height:40px}
.loading_in_bar {position:absolute; top:0; left:0; height:40px; background:var(--GridaBlue)}
.down_loading_bar_w .loading_title {margin-top:7px; text-align:center; font-size:17px}
.loading_time {display:flex; justify-content:space-between; margin-bottom:5px}
.loading_time p {font-size:13px}
.loading_time p strong {color:#333}

.down_loading_wrap .loading_time {margin-top:30px}
.down_loading_wrap + .produce_spec_wrap {margin-top:10px}
.loader {margin:84px auto 53px; font-size:18px; width:20px; height:20px; border-radius:50%; position:relative; text-indent:-9999em; -webkit-animation:load5 1.1s infinite ease; animation:load5 1.1s infinite ease; -webkit-transform:translateZ(0); transform:translateZ(0)}
.down_loading {font-size:20px; text-align:center}
@-webkit-keyframes load5 {
  0%, 100% {box-shadow: 0em -2.6em 0em 0em var(--GridaBlue), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.5), -1.8em -1.8em 0 0em rgba(1,123,251, 0.7)}
  12.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.7), 1.8em -1.8em 0 0em var(--GridaBlue), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.5)}
  25% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.5), 1.8em -1.8em 0 0em rgba(1,123,251, 0.7), 2.5em 0em 0 0em var(--GridaBlue), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  37.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.5), 2.5em 0em 0 0em rgba(1,123,251, 0.7), 1.75em 1.75em 0 0em var(--GridaBlue), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  50% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.5), 1.75em 1.75em 0 0em rgba(1,123,251, 0.7), 0em 2.5em 0 0em var(--GridaBlue), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  62.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.5), 0em 2.5em 0 0em rgba(1,123,251, 0.7), -1.8em 1.8em 0 0em var(--GridaBlue), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  75% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.5), -1.8em 1.8em 0 0em rgba(1,123,251, 0.7), -2.6em 0em 0 0em var(--GridaBlue), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  87.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.5), -2.6em 0em 0 0em rgba(1,123,251, 0.7), -1.8em -1.8em 0 0em var(--GridaBlue)}
}
@keyframes load5 {
  0%, 100% {box-shadow: 0em -2.6em 0em 0em var(--GridaBlue), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.5), -1.8em -1.8em 0 0em rgba(1,123,251, 0.7)}
  12.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.7), 1.8em -1.8em 0 0em var(--GridaBlue), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.5)}
  25% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.5), 1.8em -1.8em 0 0em rgba(1,123,251, 0.7), 2.5em 0em 0 0em var(--GridaBlue), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  37.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.5), 2.5em 0em 0 0em rgba(1,123,251, 0.7), 1.75em 1.75em 0 0em var(--GridaBlue), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  50% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.5), 1.75em 1.75em 0 0em rgba(1,123,251, 0.7), 0em 2.5em 0 0em var(--GridaBlue), -1.8em 1.8em 0 0em rgba(1,123,251, 0.2), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  62.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.5), 0em 2.5em 0 0em rgba(1,123,251, 0.7), -1.8em 1.8em 0 0em var(--GridaBlue), -2.6em 0em 0 0em rgba(1,123,251, 0.2), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  75% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.5), -1.8em 1.8em 0 0em rgba(1,123,251, 0.7), -2.6em 0em 0 0em var(--GridaBlue), -1.8em -1.8em 0 0em rgba(1,123,251, 0.2)}
  87.5% {box-shadow: 0em -2.6em 0em 0em rgba(1,123,251, 0.2), 1.8em -1.8em 0 0em rgba(1,123,251, 0.2), 2.5em 0em 0 0em rgba(1,123,251, 0.2), 1.75em 1.75em 0 0em rgba(1,123,251, 0.2), 0em 2.5em 0 0em rgba(1,123,251, 0.2), -1.8em 1.8em 0 0em rgba(1,123,251, 0.5), -2.6em 0em 0 0em rgba(1,123,251, 0.7), -1.8em -1.8em 0 0em var(--GridaBlue)}
}

.sto_add_input {margin-top:20px}
.sto_add_input.w02 {margin-top:5px}
.sto_add_input div {margin-bottom:5px; display:flex; }
.sto_add_input div input {width:73%; height:40px; margin-right:0; padding:0 15px; margin-bottom:7px; border:1px solid #aaa; }
.sto_add_input div label {width:14%; line-height:37px}
.sto_add_input + p {text-align:center}
.sto_add_input.w02 {margin-top:0}

textarea{ border: 1px solid rgb(154, 154, 154) ; }
.arrow_input_wrap {position:relative; }
.arrow_input_wrap select{width: 80px; text-align: center;}
/* .arrow_input_wrap select {margin-left: 20px;} */
.arrow_input {position:absolute; top:1px; right:1px; flex-direction:column; border-radius:0 5px 5px 0; overflow:hidden}
.arrow_input button {height:20px; width:40px; border:solid #ddd; border-width:0 0 1px 1px; background:#fff}
.arrow_input button:last-child {border-bottom:0}
.arrow_input svg {height:15px; color:#666}
.sto_add_input textarea{  width: 330px;height: 100px;margin-top: 10px;resize: none;padding: 10px;}
.user_pop_content.no_padding {padding:0}
.add_con_wrap:after, .sg_select:after {content:''; clear:both; display:block}
.add_con_wrap {padding:10px; background:#f1f1f1}
.add_con_wrap .add_con_li {float:left; width:25%}
.add_con_wrap .add_con_li:nth-child(1), .add_con_wrap .add_con_li:nth-child(3), .add_con_wrap .add_con_li:nth-child(4) {width:14%}
.add_con_wrap .add_con_li:nth-child(2) {float:left; width:31%}
.add_con_wrap .add_con_li:nth-child(5) {float:left; width:27%}
.add_con_wrap .add_con_li dl {margin-left:3px; background:#fff}
.add_con_wrap .add_con_li:first-child dl {margin-left:0px}
.add_con_wrap .add_con_li dl dt {line-height:30px; background:#1c54b3; color:#fff; text-align:center}
.add_con_wrap .add_con_li dl dd {padding:10px; font-size:13px}
.add_con_wrap .add_con_li:last-child dl dd {max-height:225px; overflow-y:auto}
.add_con_wrap .add_radio {display:flex; justify-content:space-between; flex-flow:column; margin-bottom:20px}
.add_con_wrap .add_radio:last-child {margin-bottom:0}
.add_con_wrap .add_radio p {text-align:center; background:#f1f1f1; padding:5px; margin-top:5px}
.add_con_wrap .add_boot_gb input {margin-bottom:0}
.add_con_wrap .add_boot_gb p {font-weight: 600; margin-bottom:10px}
.sg_select {margin-top:7px}
.sg_select:first-child {margin-top:0px}
.sg_select_right{float: right}
.sg_select_left {float:left; line-height:28px}
.sg_select_left input[type="radio"] + label::before {top:-3px}
.sg_select_left input[type="radio"] + label::after {top:3px}
.sg_select_right select {width:55px; border-radius:5px; margin:0; border:1px solid #aaa; box-sizing:border-box; padding:5px 10px}
.add_sg_btn {width:100%; margin-top:20px; border-radius:5px; line-height:30px; text-align:center; color:#fff; background:var(--GridaBlue); border:0}
.add_sg_btn:hover {background:#006ee4; }
.add_sg_btn.w02 {margin-top:10px}
.add_sr_text {text-align:center; margin-top:15px}
.toggleSwitch {width:16px; height:26px; display:block; position:relative; border-radius:60px; background-color:#eee; cursor:pointer}
.toggleSwitch .toggleButton {width:23px; height:23px; position: absolute; top:50%; left:3px; -webkit-transform:translateY(-50%); transform:translateY(-50%); border-radius:50%; background:#aaa}
.alarm_toggle:checked ~ .toggleSwitch {background:var(--GridaBlue)}
.alarm_toggle:checked ~ .toggleSwitch .toggleButton {left:calc(100% - 25px); background:#fff}
.toggleSwitch, .toggleButton {transition:all 0.2s ease-in}
.add_height {min-height:310px }
#add_height {height: 310px; overflow-y: auto;}
.storage_add_p {margin-top:10px}
.add_con_wrap .sto_add_input div input {height:36px}
.add_con_wrap .sto_add_input .arrow_input button {height:18px}
.drive_se_wrap {padding:10px; margin-top:7px; background:#f1f1f1}
.user_pop_content .pop_alarm_p input {border:1px solid #aaa; text-align:center}

/* 1280이하 미디어쿼리 */
@media (max-width: 1300px) {
  .user_navi_wrap {width:200px}
  
}

@media (max-width: 1124px) {
.user_box_wrap .user_box li {margin:7px}
}

@media (max-width: 1266px) {
.user_right_con .user_combo_wrap {display:block}
.combo_left .combo_tab, .combo_right .dropdown {float:right}
.combo_left {margin-bottom:30px}
.combo_right .user_search_wrap {width:485px}
.combo_right .user_search_wrap input {width:93%}
.user_right_con.my_history_wrap .user_combo_wrap {display:flex}
}


@media (max-width: 1024px) {
.history_table {width:700px; overflow:auto}
.history_table table, .history_table .hitory_td_wrap{width:1024px}
}



.modal_background{
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.382);
}

.blink { -webkit-animation: blink-effect 1s step-end infinite; animation: blink-effect 1s step-end infinite;}
.warning{-webkit-animation: blink-font 1s ;animation: blink-font 1s ;}


@-webkit-keyframes blink-effect {
  50% {opacity: 0; }
}


@keyframes blink-effect {
  50% {opacity: 0; }
}
@-webkit-keyframes blink-font {
  25% { background-color: #ffd3d358; }
  50% { background-color: #ffffff; }
  75% { background-color: #ffd3d358; }
}
@keyframes blink-font {
  25% { background-color: #ffd3d358; }
  50% { background-color: #ffffff; }
  75% { background-color: #ffd3d358; }
}



.download_see {
  position:relative; 
  font-size:15px;  
  margin:27px; 
  border: none;
   border-radius:5px; font-weight:600; 
}


 .download_see .submenu {min-width:140px; overflow:hidden; box-shadow:2px 2px 7px #ddd; 
  height:auto; position:absolute; top:0px; right:0px; z-index:5; opacity:0; visibility:hidden; transition:.3s; border-radius:5px}
 .download_see .submenu li a {padding:5px 5px; background:#fff; font-size:15px;	display:block; color:#333; transition:.3s; line-height:28px}
 .download_see:hover .submenu {opacity:1; visibility:visible; top:40px; background:#fff}
 .download_see .submenu li {min-width:120px; border-radius:0; border:solid #ddd; border-width:1px 1px 0 1px; margin:0}
 .download_see .submenu li:last-child {border-bottom:1px solid #ddd}
 .download_see .submenu li a:hover {background:var(--GridaBlue); color:#fff}
 .download_see .submenu li a::after {width:0; height:0}






.user_pop_content {padding:20px; position: relative; }


#red{ color: #ff2c2c;}


.pop_radio_wrap label div{
  margin-top: 4px;
}

#spec_select{
  background-color: #bcdcff;
}
#select_focus{
  border: 1.8px solid rgb(0, 50, 232);
}

.shared_icon{
  width: 20px;
}
.shared_icon img{
  height: 14px;
  margin-right: 3px;
  margin-bottom: -2px;
  -webkit-filter: invert(35%) sepia(89%) saturate(6054%) hue-rotate(180deg);
          filter: invert(35%) sepia(89%) saturate(6054%) hue-rotate(180deg);
}

#scroll_bar::-webkit-scrollbar {
  width: 7px;
}
#scroll_bar::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
    border-radius: 10px;
}
#scroll_bar::-webkit-scrollbar-track {
    background-color: #ffffff;
}

:root{
  --Black : #333;
  --White : #fff;
  --Warning :#e04e4e;
  --WarningHover :#de3d3d;
  

  --PowerOff:#41423e;
  --Booting:#cbd55f;
  --Running:#77b63f;
  --ShuttingDown:#b6b6b6;
  --NotPowerOff:#e4a159;
  --Error:#e04e4e;

  --Active : #4780ed;
  --ActiveHover : #407ced;

  --InActive : #eaf3fe;
  --InActiveHover : #deedff;

  --GridaBlue :#017bfb;
  --GridaBlueHover :#006ee4;
  --GridaBlueLight :#deedff;
  
  --GridaMint :#01BBC9;
  --GridaGray : #626166;

  --LightGray:#C0C2C6;
  --LightGrayHover:#adafb4;
}
#blue{
  color:#017bfb;
  color:var(--GridaBlue); font-weight: 600;
}

.react-calendar {
  width: 250px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0.8em;
  margin: auto;
  border-radius: 5px;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6px;
  background: none;
  text-align: center;
  line-height: 6px;
  font-size: 0.7rem;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 25px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6c0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.5em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #f14444;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

