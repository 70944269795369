:root{
  --Black : #333;
  --White : #fff;
  --Warning :#e04e4e;
  --WarningHover :#de3d3d;
  

  --PowerOff:#41423e;
  --Booting:#cbd55f;
  --Running:#77b63f;
  --ShuttingDown:#b6b6b6;
  --NotPowerOff:#e4a159;
  --Error:#e04e4e;

  --Active : #4780ed;
  --ActiveHover : #407ced;

  --InActive : #eaf3fe;
  --InActiveHover : #deedff;

  --GridaBlue :#017bfb;
  --GridaBlueHover :#006ee4;
  --GridaBlueLight :#deedff;
  
  --GridaMint :#01BBC9;
  --GridaGray : #626166;

  --LightGray:#C0C2C6;
  --LightGrayHover:#adafb4;
}
#blue{
  color:var(--GridaBlue); font-weight: 600;
}
